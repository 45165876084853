import { CellContext } from '@tanstack/react-table';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { cx } from 'utils';
import { AggregateListRow } from 'types/settings';

const MilStatusCell = ({ cell }: { cell: CellContext<AggregateListRow, string> }) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="w-full flex-center">
      <Tooltip
        text={
          cell.getValue()
            ? tSafe('components.Vehicle.VehicleList.cells.MilStatusCell.mil-is-on', { defaultValue: 'MIL is on' })
            : tSafe('components.Vehicle.VehicleList.cells.MilStatusCell.mil-is-off', { defaultValue: 'MIL is off' })
        }
        side="right"
      >
        <Icon name="engineLightning" className={cx('!w-4', cell.getValue() ? 'text-error' : 'text-success')} />
      </Tooltip>
    </div>
  );
};

export default MilStatusCell;
