import { useContext } from 'react';

import { batteryNotificationIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import Widget, { WidgetPageType } from 'components/Dashboard/Widget';
import { BatteryNotificationType, BatteryState, DashboardLightMessageType, DtcEventType } from 'generated/graphql';
import { cx } from 'utils';

import { useVehicleDetails } from '../hooks';
import { StatusItem } from './StatusItem';

const Status = () => {
  const i18nContext = useContext(I18nContext);
  const { loading, error, data: [vehicleDetails] = [] } = useVehicleDetails();

  if (loading || !vehicleDetails || !i18nContext) return null;

  const {
    commonTranslations: {
      errors: { error_text },
      enums: {
        batteryNotificationTypeDescriptionMap,
        dtcEventTypeDescriptionMap,
        dashboardLightMessageTypeDescriptionMap,
      },
      general: { unavailable_text },
      domain: {
        vehicleDetails: {
          fields: { batteryStatus_text, battery_voltage_text, service_status_text, battery_warnings_text, status_text },
        },
      },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  const {
    status: { batteryStatus: batteryStatusNotificationType, mil },
    batteryStatus,
    activeDtcs,
    exceptions: { battery: batteryExceptions },
  } = vehicleDetails;

  const lastVoltage = batteryStatus.currentState?.lastVoltage;
  const batteryWarnings = batteryExceptions.filter((x) => x.type !== BatteryState.NotificationOk).length;
  const dtcStoredCount = activeDtcs.filter((x) => x.dtcType === DtcEventType.Stored).length;
  const dtcPendingCount = activeDtcs.filter((x) => x.dtcType === DtcEventType.Pending).length;
  const dtcPermanentCount = activeDtcs.filter((x) => x.dtcType === DtcEventType.Permanent).length;

  const warning =
    batteryStatusNotificationType !== BatteryNotificationType.Ok ||
    !!batteryWarnings ||
    !!dtcStoredCount ||
    !!dtcPendingCount ||
    !!dtcPermanentCount ||
    !!mil;

  return (
    <Widget title={status_text} warning={warning} collapsible page={WidgetPageType.Vehicle}>
      <div className="w-full text-md p-1 h-25 bg-white" data-testid="vehicle-status">
        <div className="flex">
          <div className="flex flex-col">
            <StatusItem
              description={service_status_text}
              value={unavailable_text}
              iconName="overdueService"
              iconClassName="!w-3"
            />

            <StatusItem
              description={batteryStatus_text}
              value={
                batteryStatusNotificationType
                  ? batteryNotificationTypeDescriptionMap[batteryStatusNotificationType]
                  : unavailable_text
              }
              iconName={
                batteryStatusNotificationType
                  ? batteryNotificationIconMap[batteryStatusNotificationType][0]
                  : batteryNotificationIconMap[batteryStatusNotificationType ?? BatteryNotificationType.Unavailable][0]
              }
              iconClassName={cx(
                '!w-3',
                batteryNotificationIconMap[batteryStatusNotificationType ?? BatteryNotificationType.Unavailable][1],
              )}
            />

            <StatusItem
              description={battery_voltage_text}
              value={lastVoltage ?? unavailable_text}
              iconName="batteryVoltage"
              iconClassName="!w-3"
            />

            <StatusItem
              description={battery_warnings_text}
              value={batteryWarnings}
              iconName="batteryWarning"
              iconClassName={cx('!w-3', batteryWarnings ? 'text-amber' : 'text-success')}
            />
          </div>

          <div className="flex flex-col ml-4">
            <StatusItem
              description={dashboardLightMessageTypeDescriptionMap[DashboardLightMessageType.EngineMilStatus]}
              value={!!mil ? 'On' : 'Off'}
              iconName="engine"
              iconClassName={cx('!w-4', !!mil ? 'text-error' : 'text-success')}
            />

            <StatusItem
              description={dtcEventTypeDescriptionMap[DtcEventType.Stored]}
              value={dtcStoredCount}
              iconName="engineLightning"
              iconClassName={cx('!w-4', dtcStoredCount ? 'text-error' : 'text-success')}
            />

            <StatusItem
              description={dtcEventTypeDescriptionMap[DtcEventType.Pending]}
              value={dtcPendingCount}
              iconName="engine"
              iconClassName={cx('!w-4', dtcPendingCount ? 'text-error' : 'text-success')}
            />

            <StatusItem
              description={dtcEventTypeDescriptionMap[DtcEventType.Permanent]}
              value={dtcPermanentCount}
              iconName="engineInfinity"
              iconClassName={cx('!w-4', dtcPermanentCount ? 'text-error' : 'text-success')}
            />
          </div>
        </div>
      </div>
    </Widget>
  );
};

export default Status;
