import { useMutation } from '@apollo/client';
// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enGB } from 'date-fns/locale';
import { useContext, useState } from 'react';
import tw from 'twin.macro';

import Button from 'atoms/Button';
import { CopyToClipboardButton } from 'atoms/CopyToClipboardButton';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import Widget, { WidgetPageType } from 'components/Dashboard/Widget';
import useRole from 'components/User/useRole';
import EditableExternalIdLabel from 'components/Vehicle/Detail/DeviceInfo/EditableExternalIdLabel';
import { useVehicleDetails } from 'components/Vehicle/Detail/hooks';
import { DeviceType, VehicleDeviceAssociationDoc } from 'generated/graphql';

export const title = 'Connection Information';

const Label = tw.span`font-bold text-md`;

const Item = ({ label, value, copyable }: { label: string; value?: string | null; copyable?: boolean }) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { unavailable_text },
    },
  } = i18nContext;

  return (
    <>
      <Label>{label}</Label>

      <span className="flex px-1 py-0.5">
        {value ?? unavailable_text}

        {copyable && value ? <CopyToClipboardButton value={value} className="ml-1" /> : null}
      </span>
    </>
  );
};

const DeviceInfo = () => {
  const i18nContext = useContext(I18nContext);
  const { data: [vehicleDetails] = [] } = useVehicleDetails();
  const { isManager } = useRole();
  const [confirmRemoveDevice, setConfirmRemoveDevice] = useState(false);

  const [updateVehicleAssociationMutation, { loading: deviceRemovalMutationLoading }] = useMutation(
    VehicleDeviceAssociationDoc,
    {
      onCompleted: () => {},
      update: (cache) => {
        cache.modify({
          id: cache.identify(vehicleDetails),
          fields: {
            associations(_, { toReference }) {
              return toReference(`Association:{"deviceId":null}`);
            },
          },
          broadcast: true,
        });
      },
    },
  );

  const { device } = vehicleDetails.associations;
  const {
    vehicle: { id: vehicleId },
  } = vehicleDetails;

  const { id, connected, firstConnected, reporting, type, imei, model, firmware, externalId, latestHeartbeat } =
    device!;
  const dateFormat = 'Pp';

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      forms: {
        buttons: { cancel_text, confirm_text },
      },
      domain: {
        device: {
          fields: {
            model_text,
            firmware_text,
            imei_text,
            firstConnected_text,
            latestHeartbeat_text,
            reporting_text,
            notReporting_text,
            reportingStatus_text,
            device_guid_text,
            externalId_text,
          },
        },
        vehicle: {
          fields: { guid_text },
        },
        vehicleDetails: {
          fields: { connectionInformation_text },
        },
      },
    },
  } = i18nContext;

  return (
    <Widget title={connectionInformation_text} collapsible page={WidgetPageType.Vehicle}>
      <div data-testid="device-info">
        {confirmRemoveDevice ? (
          <div className="w-[75%] mx-auto mt-[80px] py-1 text-center">
            <IonIcon name="warningOutline" className="text-3xl text-error mb-1" />

            <h3 className="flex-center mb-1 text-xl font-semibold whitespace-nowrap">
              {tSafe('components.Vehicle.Detail.DeviceInfo.confirm-device-removal', {
                defaultValue: 'Confirm Device Removal',
              })}
            </h3>

            <div>
              <p>
                {tSafe('components.Vehicle.Detail.DeviceInfo.are-you-sure', {
                  defaultValue: 'Are you sure? This will remove the device from this vehicle.',
                })}
              </p>

              {tSafe('components.Vehicle.Detail.DeviceInfo.once-removed-no-longer-visible', {
                defaultValue: 'Once removed, this vehicle will no longer be visible in the portal!',
              })}
            </div>

            <div className="flex-center mt-2 w-full gap-1">
              <Button
                className="flex-center min-w-6 px-1 py-0.5 border rounded-4 text-error"
                onClick={() => updateVehicleAssociationMutation({ variables: { vehicleId, externalId: null } })}
              >
                {confirm_text}
              </Button>

              <Button
                className="flex-center min-w-6 px-1 py-0.5 border rounded-4"
                onClick={() => setConfirmRemoveDevice(false)}
              >
                {cancel_text}
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-2 px-3 pt-1">
            <div className="grid gap-x-1 items-center grid-cols-[auto,1fr]">
              {type && <Item label="Connection Type" value={type} />}

              {firmware && <Item label={firmware_text} value={firmware} />}

              {imei && <Item label={imei_text} value={imei} copyable />}

              {model && <Item label={model_text} value={model} copyable />}

              {type === DeviceType.Dongle && (
                <>
                  <Item
                    label={firstConnected_text}
                    value={firstConnected ? format(parseISO(firstConnected), dateFormat, { locale: enGB }) : null}
                  />

                  <Item
                    label={latestHeartbeat_text}
                    value={latestHeartbeat ? format(parseISO(latestHeartbeat), dateFormat, { locale: enGB }) : null}
                  />
                </>
              )}

              {connected && !!!reporting && (
                <Item label={reportingStatus_text} value={reporting ? reporting_text : notReporting_text} />
              )}

              <Item label={device_guid_text} value={id} copyable />

              <Item label={guid_text} value={vehicleId} copyable />

              {type === DeviceType.Dongle && isManager ? (
                <>
                  <Label>{externalId_text}</Label>

                  <span className="flex px-1 py-0.5">
                    <EditableExternalIdLabel externalId={externalId.toUpperCase()} />
                  </span>
                </>
              ) : (
                <Item label={externalId_text} value={externalId.toUpperCase()} copyable />
              )}

              {deviceRemovalMutationLoading && (
                <div className="animate-pulse mt-1">
                  {tSafe('components.Vehicle.Detail.DeviceInfo.device-removal-in-progress', {
                    defaultValue: 'Device removal in progress...',
                  })}
                </div>
              )}
            </div>

            <div className="flex-center">
              {type === DeviceType.Dongle && isManager && !deviceRemovalMutationLoading && (
                <Button
                  onClick={() => setConfirmRemoveDevice(true)}
                  className="mt-1 text-error border-px rounded py-0.5 px-1 rounded-4 "
                >
                  {tSafe('components.Vehicle.Detail.DeviceInfo.button.remove-from-vehicle', {
                    defaultValue: 'Remove from vehicle',
                  })}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </Widget>
  );
};
export default DeviceInfo;
