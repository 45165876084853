import { useState } from 'react';

import DriverSearch from 'atoms/DriverSearch';
import DriverCard from 'atoms/DriverSearch/DriverSelectorWithSearch/DriverCard';
import { cx } from 'utils';

interface DriverSelectorWithSearchProps {
  fleetId: string;
  onSelect: (id: string) => void;
  onDeselect: () => void;
  initialDriverId?: string;
  className?: string;
  searchContainerClassName?: string;
  driverCardContainerClassName?: string;
  searchInputClassName?: string;
  searchResultsClassName?: string;
}

const DriverSelectorWithSearch = ({
  fleetId,
  onSelect,
  onDeselect,
  initialDriverId,
  className,
  searchContainerClassName,
  driverCardContainerClassName,
  searchInputClassName,
  searchResultsClassName,
}: DriverSelectorWithSearchProps) => {
  const [driverId, setDriverId] = useState(initialDriverId);

  return (
    <div className={cx('', className)}>
      {driverId ? (
        <div className={cx('w-40', driverCardContainerClassName)}>
          <DriverCard
            id={driverId}
            onClickDelete={() => {
              setDriverId(undefined);
              onDeselect();
            }}
          />
        </div>
      ) : (
        <div className={cx('w-full', searchContainerClassName)}>
          <DriverSearch
            onSelectDriver={(id) => {
              setDriverId(id);
              onSelect(id);
            }}
            fleetId={fleetId}
            searchInputClassName={searchInputClassName}
            searchResultsClassName={searchResultsClassName}
          />
        </div>
      )}
    </div>
  );
};

export default DriverSelectorWithSearch;
