import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useState } from 'react';

import { I18nContextProvider } from 'common/useT';
import { useCurrentFleetId, useLoadCurrentFleetId } from 'components/FleetSelector/hooks';
import useTenant from 'components/Tenant/useTenant';
import { ApiError, GetUserDoc } from 'generated/graphql';
import { getError, useQ } from 'utils/apolloClient';

interface RequireUserProps {
  children: ReactNode;
}

/*
  NB: you can't use useT() here
*/
const RequireUser = ({ children }: RequireUserProps) => {
  const { logout } = useAuth0();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { data: user, error } = useQ(GetUserDoc, {
    onError: (e) => {
      if (getError(e)?.type === ApiError.UserNotFound) {
        logout();
      }
    },
  });
  useTenant({ fetchPolicy: 'cache-first' });

  useLoadCurrentFleetId();
  const currentFleetId = useCurrentFleetId({ optional: true });

  if (!user) return null;

  if (isRedirecting)
    return (
      <div className="h-full flex-center">
        <h1 className="text-2xl">Redirecting...</h1>
      </div>
    );

  if (!window.location.hostname.match(/.+?\.fleet/)) {
    if (user.subdomain) {
      setIsRedirecting(true);
      window.location.hostname = window.location.hostname.replace('fleet', `${user.subdomain}.fleet`);
    }

    return (
      <div className="h-full flex-center">
        <h1 className="text-2xl">Please use a subdomain to access the portal</h1>
      </div>
    );
  }

  if (
    user?.accessibleFleets.length === 0 ||
    getError(error)?.type === ApiError.TenantNotFound ||
    getError(error)?.type === ApiError.Unauthorized
  ) {
    return (
      <div className="h-full flex-center">
        <h1 className="text-2xl">Please check if the subdomain is correct</h1>
      </div>
    );
  }

  if (!currentFleetId || !user.accessibleFleets.some((fleet) => fleet.id === currentFleetId)) return null;

  return (
    <>
      <I18nContextProvider>{children}</I18nContextProvider>
    </>
  );
};

export default RequireUser;
