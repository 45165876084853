import { useState } from 'react';

import VehicleSearch from 'atoms/VehicleSearch';
import VehicleCard from 'atoms/VehicleSearch/VehicleSelectorWithSearch/VehicleCard';
import { cx } from 'utils';

interface VehicleSelectorWithSearchProps {
  fleetId: string;
  onSelect: (id: string) => void;
  onDeselect: () => void;
  initialVehicleId?: string;
  className?: string;
  searchContainerClassName?: string;
  vehicleCardContainerClassName?: string;
  searchInputClassName?: string;
  searchResultsClassName?: string;
}

const VehicleSelectorWithSearch = ({
  fleetId,
  onSelect,
  onDeselect,
  initialVehicleId,
  className,
  searchContainerClassName = 'w-full',
  vehicleCardContainerClassName,
  searchInputClassName,
  searchResultsClassName,
}: VehicleSelectorWithSearchProps) => {
  const [vehicleId, setVehicleId] = useState(initialVehicleId);

  return (
    <div className={cx('', className)}>
      {vehicleId ? (
        <div className={cx('w-40', vehicleCardContainerClassName)}>
          <VehicleCard
            id={vehicleId}
            onClickDelete={() => {
              setVehicleId(undefined);
              onDeselect();
            }}
          />
        </div>
      ) : (
        <div className={cx(searchContainerClassName)}>
          <VehicleSearch
            onSelectVehicle={(id) => {
              setVehicleId(id);
              onSelect(id);
            }}
            fleetId={fleetId}
            searchInputClassName={searchInputClassName}
            searchResultsClassName={searchResultsClassName}
          />
        </div>
      )}
    </div>
  );
};

export default VehicleSelectorWithSearch;
