import { useMutation } from '@apollo/client';
import * as Menu from '@radix-ui/react-dropdown-menu';
import { useContext, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import ButtonWithConfirmModal from 'atoms/ButtonWithConfirmModal';
import IonIcon from 'atoms/IonIcon';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import ListView from 'components/List/ListView';
import { SaveListViewSettingsDoc } from 'generated/graphql';
import { cx, entries, keys } from 'utils';
import { successToast } from 'utils/toasts';

import SaveListViewToolbarItem from './ListView/ListViewToolbar/SaveListViewToolbarItem';
import { listState } from './state';
import useSettings from 'components/Settings/useSettings';
import { defaultListName } from './utils';

const ListPage = () => {
  const { listViewSettings } = useSettings();
  const i18nContext = useContext(I18nContext);
  const [listRecoilState, setListRecoilState] = useRecoilState(listState);
  const [updateListView] = useMutation(SaveListViewSettingsDoc, { fetchPolicy: 'no-cache' });
  const [deleteListView] = useMutation(SaveListViewSettingsDoc, {
    onCompleted: (e) => {
      successToast(tSafe('components.ListPage.toasts.list-view-deleted', { defaultValue: 'List view deleted' }));
    },
  });

  useEffect(() => {
    setListRecoilState({
      ...listRecoilState,
      activeListViewName: listViewSettings.activeListView,
    });
  }, [listViewSettings.activeListView]);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      forms: {
        buttons: { delete_text, cancel_text },
      },
    },
  } = i18nContext;

  const deleteView = (name: string) => {
    const newView = { ...listRecoilState.allListViews };
    delete newView[name];

    setListRecoilState({
      ...listRecoilState,
      activeListViewName: keys(newView)[0] as string,
      allListViews: newView,
    });

    deleteListView({ variables: { activeListView: keys(newView)[0] as string, listViews: newView } });
  };

  return (
    <div className="relative px-2">
      <div className={cx(`flex items-center absolute top-1.5 left-[15px]`)}>
        <h1 className="text-md font-bold">{listRecoilState.activeListViewName}</h1>

        <Menu.Root>
          <Menu.Trigger className="">
            <Tooltip text="Select list view">
              <div className="ml-1 border-px cursor-pointer ui-button">
                <IonIcon name="chevronDownOutline" />
              </div>
            </Tooltip>
          </Menu.Trigger>

          <Menu.Content
            data-state
            className="relative min-w-20 bg-white rounded-4 shadow-card text-sm overflow-y-scroll"
          >
            {[...entries(listRecoilState.allListViews)]
              .sort(([key]) => (key === defaultListName ? -1 : 1))
              .map(([key, value]) => (
                <div className="flex" key={key}>
                  <Menu.DropdownMenuItem className="flex-1">
                    <Button
                      className={cx(
                        'w-full py-2 p-1 pl-2 flex hover:bg-gray-100',
                        key === listRecoilState.activeListViewName && 'border-l-[4px] border-primary',
                      )}
                      style={{ borderColor: '#314359' }}
                      onClick={() => {
                        setListRecoilState({
                          ...listRecoilState,
                          activeListViewName: key,
                        });
                        updateListView({ variables: { listViews: listRecoilState.allListViews, activeListView: key } });
                      }}
                    >
                      {key}
                    </Button>
                  </Menu.DropdownMenuItem>
                  <div className="flex">
                    {!value.default && (
                      <>
                        <SaveListViewToolbarItem isInEditMode={true} listNameToEdit={key} />

                        <ButtonWithConfirmModal
                          className="flex text-error hover:bg-gray-100 py-2 px-1"
                          confirmTitle={tSafe('atoms.Modal.DeleteListModal.title', {
                            defaultValue: 'Delete List',
                          })}
                          confirmContent={tSafe('atoms.Modal.DeleteListModal.content', {
                            defaultValue: 'Are you sure you want to delete list view {{listName}}?',
                            listName: key,
                          })}
                          confirmButtonText={delete_text}
                          rejectButtonText={cancel_text}
                          confirmYesButtonClassName="flex-center min-w-6 px-1 py-0.5 border rounded-4 text-error ui-button"
                          confirmNoButtonClassName="flex-center min-w-6 px-1 py-0.5 border rounded-4 ui-button"
                          onConfirm={() => deleteView(key)}
                        >
                          <IonIcon name="trashOutline" />
                        </ButtonWithConfirmModal>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </Menu.Content>
        </Menu.Root>
      </div>

      <ListView />
    </div>
  );
};

export default ListPage;
