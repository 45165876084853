import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

import { defaultDashboardTimePeriod } from 'common/constants';
import { batteryStateIconMap, dashboardLightsIconMap, dtcIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import { BatteryNotificationType, BatteryState, GetVehicleDetailsDoc } from 'generated/graphql';
import { EventWarning } from 'types/mapVehicleDetails';
import { useQ } from 'utils/apolloClient';

import { selectedVehicleState } from '../state';
import { EventListContainer } from './EventListContainer';

const VehicleDetails = () => {
  const i18nContext = useContext(I18nContext);
  const selectedVehicle = useRecoilValue(selectedVehicleState)!;
  const {
    loading,
    error,
    data: [vehicle] = [],
  } = useQ(GetVehicleDetailsDoc, {
    variables: {
      vehicleIds: selectedVehicle.vehicleId,
      period: defaultDashboardTimePeriod,
    },
  });

  const metrics: EventWarning[] = [];

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      errors: { failed_to_fetch_data_text },
      general: { loading_text },
      domain: {
        vehicleDetails: {
          fields: { battery_health_text, mil_status_text, fuel_status_text, activity_text: vehicle_events_text },
        },
      },
    },
  } = i18nContext;

  if (error) return <div>{failed_to_fetch_data_text}</div>;
  if (loading || !vehicle) return <div className="flex-center mt-4 text-md capitalize">{loading_text}</div>;
  if (!selectedVehicle) return null;

  const {
    status: { fuel, batteryStatus, mil },
    exceptions,
  } = vehicle;

  if (fuel && fuel === 0) {
    metrics.push({
      description: fuel_status_text,
      value: fuel || 0,
      iconName: 'fuelconsumption',
      warning: true,
    });
  }

  if (batteryStatus !== BatteryNotificationType.Ok) {
    metrics.push({
      description: battery_health_text,
      value: batteryStatus ?? BatteryNotificationType.Unavailable,
      iconName: 'batteryNotEstimated',
      warning: true,
    });
  }

  if (!!mil) {
    metrics.push({
      description: mil_status_text,
      value: 'On',
      iconName: 'engine',
      warning: true,
    });
  }

  const dashboardLights: EventWarning[] = exceptions.dashboardLight.map((dl) => ({
    description: dl.description,
    value: dl.count,
    iconName: dashboardLightsIconMap[dl.type],
    warning: !!dl.count,
  }));

  const batteryEvents: EventWarning[] = exceptions.battery
    .filter((x) => x.type !== BatteryState.NotificationOk)
    .map((battery) => ({
      description: battery.description,
      value: battery.count,
      iconName: batteryStateIconMap[battery.type][0],
      warning: !!battery.count,
    }));

  const dtcEvents: EventWarning[] = exceptions.dtc.map((dtc) => ({
    description: dtc.description,
    value: dtc.count,
    iconName: dtcIconMap[dtc.type],
    warning: !!dtc.count,
  }));

  const allMetrics = [...metrics, ...dashboardLights, ...batteryEvents, ...dtcEvents];

  return (
    <>
      {allMetrics.filter((x) => x.value !== 0).length ? (
        <EventListContainer metrics={allMetrics} title={vehicle_events_text} />
      ) : (
        <div className="flex-center mt-4 text-md">
          {tSafe('components.Live.VehicleSidebar.VehicleDetails.no-reported-issues', {
            defaultValue: 'No reported issues',
          })}
        </div>
      )}
    </>
  );
};

export default VehicleDetails;
