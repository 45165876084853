import { useCallback, useContext, useMemo, useState } from 'react';

import useDriverSearchSource from 'atoms/DriverSearch/useDriverSearchSource';
import IonIcon from 'atoms/IonIcon';
import Spinner from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { GetDriverSearchDoc } from 'generated/graphql';
import { cx } from 'utils';
import { useQ } from 'utils/apolloClient';

interface DriverSearchProps {
  onSelectDriver?: (id: string) => void;
  fleetId: string;
  searchInputClassName?: string;
  searchResultsClassName?: string;
}

const DriverSearch = ({ onSelectDriver, fleetId, searchInputClassName, searchResultsClassName }: DriverSearchProps) => {
  const i18nContext = useContext(I18nContext);
  const [searchTerm, setSearchTerm] = useState('');
  const resetSearchTerm = useCallback(() => setSearchTerm(''), []);

  const { data } = useQ(GetDriverSearchDoc, {
    variables: { fleetIds: [fleetId] },
    fetchPolicy: 'cache-first',
  });

  const drivers = useMemo(
    () =>
      data?.map(({ id, user: { name } }) => ({
        id,
        name,
      })) ?? [],
    [data],
  );

  const results = [
    ...useDriverSearchSource(drivers, searchTerm, (id) => {
      resetSearchTerm();
      onSelectDriver?.(id);
    }),
  ]
    .sort((a, b) => b.score - a.score)
    .map(({ jsx }) => jsx);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { loading_text },
    },
    tSafe,
  } = i18nContext;

  if (!drivers)
    return (
      <div className="flex ml-4 !w-3 !h-3 text-sm">
        <Spinner />

        <span className="ml-[8rem] w-15">{loading_text}</span>
      </div>
    );

  return (
    <div className="relative flex my-0.5 p-0.5 bg-white border-px border-gray-400 rounded-4 ">
      <input
        className={cx('pr-1 w-full bg-white focus:outline-none', searchInputClassName)}
        placeholder={tSafe('atoms.DriverSearch.search-driver-by-name', { defaultValue: 'Search for driver by name' })}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div className="text-2xl" aria-label="Search">
        <IonIcon name="searchOutline" />
      </div>

      <ul
        className={cx(
          'absolute left-0 right-0 text-sm top-[calc(100%+.5rem)] children:focus:outline-none z-999',
          searchResultsClassName,
        )}
      >
        {searchTerm && !!results?.length && (
          <div className="flex flex-col bg-gray-100 border rounded-8 overflow-hidden">
            <span className="max-h-80 overflow-auto">{results}</span>
          </div>
        )}
      </ul>
    </div>
  );
};

export default DriverSearch;
