import { useCallback } from 'react';

import IonIcon from 'atoms/IonIcon';
import useSearchSource, { SearchSourceComponent } from 'common/useSearchSource';

export type MemoizedVehicle = {
  id: string;
  licencePlate: string;
};

const SearchVehicleItem: SearchSourceComponent = ({ id, innerHtml, onSelect }) => (
  <li
    className="flex px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
    onClick={() => onSelect?.(id)}
  >
    <IonIcon name="carSportOutline" className="mr-1 text-3xl" />

    <div className="flex flex-col">
      <span className="text-md" dangerouslySetInnerHTML={{ __html: innerHtml }} />
    </div>
  </li>
);

const useVehicleSearchSource = (
  memoizedData: MemoizedVehicle[] | undefined,
  searchTerm: string,
  onSelect: (id: string) => void,
) => {
  const selectHandler = useCallback(
    (vehicle: MemoizedVehicle) => {
      onSelect?.(vehicle.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return useSearchSource({
    memoizedData,
    searchKey: 'licencePlate',
    searchTerm,
    Component: SearchVehicleItem,
    onSelect: selectHandler,
  });
};

export default useVehicleSearchSource;
