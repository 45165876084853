import React, { useCallback, useContext, useMemo, useState } from 'react';

import IonIcon from 'atoms/IonIcon';
import Spinner from 'atoms/Spinner';
import useVehicleSearchSource from 'atoms/VehicleSearch/useVehicleSearchSource';
import { I18nContext } from 'common/useT';
import { GetVehicleSearchDoc } from 'generated/graphql';
import { cx, isDefined } from 'utils';
import { useQ } from 'utils/apolloClient';

interface VehicleSearchProps {
  onSelectVehicle?: (id: string) => void;
  fleetId: string;
  searchInputClassName?: string;
  searchResultsClassName?: string;
}

const VehicleSearch = ({
  onSelectVehicle,
  fleetId,
  searchInputClassName,
  searchResultsClassName,
}: VehicleSearchProps) => {
  const i18nContext = useContext(I18nContext);
  const [searchTerm, setSearchTerm] = useState('');
  const resetSearchTerm = useCallback(() => setSearchTerm(''), []);
  const { data } = useQ(GetVehicleSearchDoc, {
    variables: { fleetIds: [fleetId] },
    fetchPolicy: 'cache-first',
  });

  const vehicles = useMemo(
    () =>
      data
        ?.map((x) => (x.vehicle ? { id: x.vehicle.id, licencePlate: x.vehicle.licencePlate } : undefined))
        .filter(isDefined),
    [data],
  );

  const results = [
    ...useVehicleSearchSource(vehicles, searchTerm, (id) => {
      resetSearchTerm();
      onSelectVehicle?.(id);
    }),
  ]
    .sort((a, b) => b.score - a.score)
    .map(({ jsx }) => jsx);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { loading_text },
    },
  } = i18nContext;

  if (!vehicles)
    return (
      <div className="flex ml-4 !w-3 !h-3 text-sm">
        <Spinner />

        <span className="ml-[8rem] w-15">{loading_text}</span>
      </div>
    );

  return (
    <div className="relative flex my-0.5 p-0.5 bg-white border-px border-gray-400 rounded-4 ">
      <input
        className={cx('pr-1 w-full bg-white focus:outline-none', searchInputClassName)}
        placeholder={tSafe('atoms.VehicleSearch.search-vehicle-by-licence-plate', {
          defaultValue: 'Search for a vehicle by licence plate',
        })}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div className="text-2xl" aria-label="Search">
        <IonIcon name="searchOutline" />
      </div>

      <ul
        className={cx(
          'absolute left-0 right-0 text-sm top-[calc(100%+.5rem)] children:focus:outline-none z-999',
          searchResultsClassName,
        )}
      >
        {searchTerm && !!results?.length && (
          <div className={cx('flex flex-col bg-gray-100 border rounded-8 overflow-hidden z-999')}>
            <span className="max-h-80 overflow-auto">{results}</span>
          </div>
        )}
      </ul>
    </div>
  );
};

export default VehicleSearch;
