import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { CopyToClipboardButton } from 'atoms/CopyToClipboardButton';
import IonIcon, { IonIconName } from 'atoms/IonIcon';
import { useModalContext } from 'atoms/Modal';
import Tooltip from 'atoms/Tooltip';
import { FleetHierarchyMode } from 'components/Fleet/FleetList';
import FleetExpandButton from 'components/Fleet/FleetList/FleetExpandButton';
import { focusedFleetTreeFleetIdState } from 'components/Fleet/FleetList/state';
import useFleetStatsMap from 'components/Fleet/FleetList/useFleetStatsMap';
import { useSetCurrentFleetId } from 'components/FleetSelector/hooks';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';
import { cast, cx } from 'utils';
import { EntityAssociation } from 'generated/graphql';

interface FleetTreeNodeProps {
  id: string;
  onFleetSelect?: (fleetId: string) => void;
  mode: FleetHierarchyMode;
}

const FleetTreeNode = ({ id, onFleetSelect, mode }: FleetTreeNodeProps) => {
  const fleet = useAccessibleFleetMap()[id];
  const stats = useFleetStatsMap({ skip: mode !== FleetHierarchyMode.MANAGEMENT })?.[id];
  const modal = useModalContext();
  const [focusedFleetTreeFleetId, setFocusedFleetTreeFleetId] = useRecoilState(focusedFleetTreeFleetIdState);
  const setCurrentFleetId = useSetCurrentFleetId();
  const isFocused = focusedFleetTreeFleetId === id;

  const clickHandler = () => {
    if (mode === FleetHierarchyMode.MANAGEMENT) {
      setFocusedFleetTreeFleetId(id);
      return;
    }
    if (onFleetSelect) {
      onFleetSelect(id);
      setFocusedFleetTreeFleetId(undefined);
      return;
    }

    setCurrentFleetId(id);
    modal?.closeModal();
  };

  return (
    <div
      className={cx(
        ' flex flex-col rounded-8 border-2 w-[200px] hover:bg-gray-300',
        isFocused ? 'border-success' : 'border-primary',
      )}
    >
      <button
        className={cx(
          'h-fit shrink-0 min-h-[30px] px-[10px] py-[8px] w-full flex-center cursor-pointer rounded-t-4',
          isFocused ? 'bg-success' : 'bg-primary',
        )}
        onClick={() => clickHandler()}
      >
        <span className="break-word text-ellipsis whitespace-normal text-[16px] text-white">
          {mode === FleetHierarchyMode.MANAGEMENT ? (
            <Tooltip text={fleet?.name}>
              <div className="flex">
                <CopyToClipboardButton value={fleet?.name!} buttonAlignment={'left'} className="mr-0.5" />

                {fleet?.name}
              </div>
            </Tooltip>
          ) : (
            <span>{fleet?.name}</span>
          )}
        </span>
      </button>

      <div className="flex-1 flex justify-around children:p-[10px] text-[18px] text-dark-gray">
        {mode === FleetHierarchyMode.MANAGEMENT ? (
          cast<[count: number | string, icon: IonIconName, associationState: EntityAssociation][]>([
            [stats?.cumulativeVehicleCount ?? '-', 'carSportOutline', EntityAssociation.AllVehicles],
            [stats?.cumulativeDriverCount ?? '-', 'driverOutline', EntityAssociation.AllDrivers],
          ]).map(([count, icon, associationState]) => (
            <Link
              key={icon}
              to={{
                pathname: `/${id}/list-view`,
                state: {
                  associationState,
                  defaultList: true,
                },
              }}
              className="hover:text-hover flex items-center gap-[5px] flex-col"
              onClick={modal?.closeModal}
            >
              <span className={cx('font-bold', count === '-' && 'animate-pulse-full')}>{count}</span>

              <IonIcon name={icon} className="text-[20px]" />
            </Link>
          ))
        ) : (
          <button
            className={cx('w-full px-[10px] flex-center cursor-pointer rounded-t-4')}
            onClick={() => clickHandler()}
          >
            <IonIcon name={'carSportOutline'} className="text-[20px]" />
          </button>
        )}
      </div>

      <div
        className={cx('flex-center translate-y-1/2', mode === FleetHierarchyMode.SELECTION ? '-bottom-1' : 'bottom-0')}
      >
        <FleetExpandButton fleetId={id} />
      </div>
    </div>
  );
};

export default FleetTreeNode;
