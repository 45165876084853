import { createColumnHelper } from '@tanstack/react-table';
import { useRecoilValue } from 'recoil';

import fuzzySearchFilter from 'atoms/react-table/tableFilters/fuzzySearchFilter';
import multiSelectFilter from 'atoms/react-table/tableFilters/multiSelectFilter';
import rangeFilter from 'atoms/react-table/tableFilters/rangeFilter';
import { tripStatisticConversionRatios } from 'common/uomConverters';
import { useT } from 'common/useT';
import { listState } from 'components/List/state';
import useSettings from 'components/Settings/useSettings';
import useUser from 'components/User/useUser';
import {
  BatteryNotificationType,
  DashboardLightMessageType,
  DtcEventType,
  ListColumnId,
  TripStatisticType,
} from 'generated/graphql';
import { capitalize, entries } from 'utils';

import BatteryHealthCell from './cells/BatteryHealthCell';
import BatteryStatusCell from './cells/BatteryStatusCell';
import DeviceStatusCell from './cells/DeviceStatusCell';
import DriverListFleetCell from './cells/DriverListFleetCell';
import DriverNameCell from './cells/DriverNameCell';
import EcoScoreCell from './cells/EcoScoreCell';
import LicencePlateCell from './cells/LicencePlateCell';
import MilStatusCell from './cells/MilStatusCell';
import DashboardLights from './DashboardLights';
import ListDtc from './ListDtcs';
import { AggregateListRow, ListItem } from 'types/settings';

export const vehicleListUniqueValueColumns: [ListColumnId, (x: ListItem) => string | null | undefined][] = [
  [ListColumnId.Make, (x) => x.vehicle?.make],
  [ListColumnId.Model, (x) => x.vehicle?.model],
  [ListColumnId.DriverName, (x) => x.driver?.name],
];

export const vehicleListMinMaxValueColumns: [ListColumnId, (x: ListItem) => number | null | undefined][] = [
  [ListColumnId.DistanceDriven, (x) => x.tripStatistics?.totalDistanceDriven ?? 0],
];

export const useListColumns = ({ useTableFiltering }: { useTableFiltering?: boolean }) => {
  const { accessibleFleets } = useUser();

  const fleetList = [...accessibleFleets]
    .map((fleet) => ({
      label: fleet.name,
      value: fleet.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const {
    commonTranslations: {
      general: { on_text, off_text, some_text, none_text, unavailable_text },
      enums: {
        batteryNotificationTypeDescriptionMap,
        batterySohStateDescriptionMap,
        dtcEventTypeDescriptionMap,
        dashboardLightMessageTypeDescriptionMap,
        deviceConnectionStatusDescriptionMap,
        tripStatisticTypeDescriptionMap,
      },
      domain: {
        fleet: { fleet_text },
        impact: { impact_events_text },
        vehicle: {
          fields: { licencePlate_text, make_text, model_text },
        },
        vehicleDetails: {
          fields: {
            mil_status_text,
            activeDashboardLights_text,
            battery_voltage_text,
            battery_health_text,
            batteryStatus_text,
          },
        },
        driver: { driver_text, longIdling_text },
        device: {
          fields: { device_connection_status_text },
        },
      },
    },
  } = useT();

  const columnHelper = createColumnHelper<AggregateListRow>();

  const { distanceInMiles, idleTimeAsPercentage, volumeInGallons } = useSettings();
  const conversionRatioMap = tripStatisticConversionRatios({ distanceInMiles, volumeInGallons });
  const {
    uniqueValues: { make, model },
  } = useRecoilValue(listState);

  const dedupedSortedMakes = Array.from(new Set(make?.map((x) => x.toUpperCase()))).sort();
  const dedupedSortedModels = Array.from(new Set(model?.map((x) => x.toUpperCase()))).sort();

  const someNoneFilter = (id: ListColumnId) =>
    multiSelectFilter(
      id,
      [
        { label: some_text, value: 'true' },
        { label: none_text, value: 'false' },
      ],
      { useTableFiltering },
    );

  const columns = [
    columnHelper.accessor<ListColumnId, string>(ListColumnId.LicencePlate, {
      cell: (props) => <LicencePlateCell cell={props} />,
      id: ListColumnId.LicencePlate,
      header: () => licencePlate_text,
      size: 120,
      meta: { filterComponent: () => fuzzySearchFilter(ListColumnId.LicencePlate), alphaSort: true },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.Make, {
      cell: (props) => props.getValue<string>()?.toUpperCase() ?? null,
      id: ListColumnId.Make,
      header: () => make_text,
      size: 140,
      filterFn: (row, id, value) => value.includes(row.getValue(id)),
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            ListColumnId.Make,
            dedupedSortedMakes.map((value) => ({
              label: value.toUpperCase(),
              value: value.toUpperCase(),
            })) ?? [],
            { useTableFiltering, searchable: true },
          ),
        alphaSort: true,
      },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.Model, {
      cell: (props) => <div className="capitalize">{props.getValue<string>()?.toLowerCase()}</div>,
      id: ListColumnId.Model,
      header: () => model_text,
      size: 140,
      filterFn: (row, id, value) => value.includes(row.getValue(id)),
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            ListColumnId.Model,
            dedupedSortedModels.map((value) => ({ label: capitalize(String(value)?.toLocaleLowerCase()), value })) ??
              [],
            { useTableFiltering, searchable: true },
          ),
        alphaSort: true,
      },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.DriverName, {
      cell: (props) => <DriverNameCell cell={props} />,
      id: ListColumnId.DriverName,
      header: () => driver_text,
      meta: { filterComponent: () => fuzzySearchFilter(ListColumnId.DriverName), alphaSort: true },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.DeviceStatus, {
      cell: (props) => <DeviceStatusCell cell={props} />,
      id: ListColumnId.DeviceStatus,
      header: () => device_connection_status_text,
      invertSorting: true,
      sortingFn: (a, b) =>
        new Date(a.original.device?.latestConnectionDate ?? 0).getTime() -
        new Date(b.original.device?.latestConnectionDate ?? 0).getTime(),
      size: 140,
      filterFn: (row, id, value) => value.includes(row.getValue(id)),
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            ListColumnId.DeviceStatus,
            entries(deviceConnectionStatusDescriptionMap).map(([type, description]) => ({
              label: description,
              value: type,
            })),
            { useTableFiltering },
          ),
      },
    }),

    columnHelper.accessor<ListColumnId, string[]>(ListColumnId.Fleets, {
      cell: (props) => <DriverListFleetCell cell={props} />,
      id: ListColumnId.Fleets,
      header: () => fleet_text,
      size: 140,
      meta: { filterComponent: () => multiSelectFilter(ListColumnId.Fleets, fleetList), alphaSort: true },
    }),

    columnHelper.accessor<ListColumnId, string>(ListColumnId.EcoScore, {
      cell: (props) => <EcoScoreCell cell={props} />,
      id: ListColumnId.EcoScore,
      header: () => tripStatisticTypeDescriptionMap[TripStatisticType.EcoScore],
      size: 140,
    }),

    columnHelper.accessor<ListColumnId, string>(ListColumnId.MilStatus, {
      cell: (props) => <MilStatusCell cell={props} />,
      id: ListColumnId.MilStatus,
      header: () => mil_status_text,
      size: 140,
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            ListColumnId.MilStatus,
            [
              { label: on_text, value: 'true' },
              { label: off_text, value: 'false' },
            ],
            { useTableFiltering },
          ),
      },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.DashboardLights, {
      cell: (props) => <DashboardLights cell={props} />,
      id: ListColumnId.DashboardLights,
      header: () => activeDashboardLights_text,
      size: 200,
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            ListColumnId.DashboardLights,
            entries(dashboardLightMessageTypeDescriptionMap)
              .filter(([type]) => type !== DashboardLightMessageType.EngineMilStatus)
              .map(([type, label]) => ({
                label,
                value: type,
              })),
            { useTableFiltering },
          ),
      },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.BatteryCharge, {
      cell: (props) => <div className="flex-center">{props.getValue<string>()}</div>,
      id: ListColumnId.BatteryCharge,
      header: () => battery_voltage_text,
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.BatteryHealth, {
      cell: (props) => <BatteryHealthCell cell={props} />,
      id: ListColumnId.BatteryHealth,
      header: () => battery_health_text,
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            ListColumnId.BatteryHealth,
            entries(batterySohStateDescriptionMap).map(([type, label]) => ({
              label: label,
              value: type,
            })),
            { useTableFiltering },
          ),
      },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.BatteryStatus, {
      cell: (props) => <BatteryStatusCell cell={props} />,
      id: ListColumnId.BatteryStatus,
      header: () => batteryStatus_text,
      meta: {
        filterComponent: () =>
          multiSelectFilter(
            ListColumnId.BatteryStatus,
            entries(batteryNotificationTypeDescriptionMap)
              .filter(
                ([type, _]) => type !== BatteryNotificationType.Drain && type !== BatteryNotificationType.Unavailable,
              )
              .map(([type, label]) => ({ label, value: type })),
            { useTableFiltering },
          ),
      },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.ImpactCount, {
      cell: (props) => <div>{props.getValue()}</div>,
      id: ListColumnId.ImpactCount,
      header: () => impact_events_text,
      meta: {
        filterComponent: () => someNoneFilter(ListColumnId.ImpactCount),
      },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.DtcPending, {
      cell: (props) => <ListDtc dtcs={props.row.original.activeDtcs ?? []} dtcType={DtcEventType.Pending} />,
      id: ListColumnId.DtcPending,
      header: () => dtcEventTypeDescriptionMap[DtcEventType.Pending],
      meta: {
        filterComponent: () => someNoneFilter(ListColumnId.DtcPending),
      },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.DtcStored, {
      cell: (props) => <ListDtc dtcs={props.row.original.activeDtcs ?? []} dtcType={DtcEventType.Stored} />,
      id: ListColumnId.DtcStored,
      header: () => dtcEventTypeDescriptionMap[DtcEventType.Stored],
      meta: {
        filterComponent: () => someNoneFilter(ListColumnId.DtcStored),
      },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.DtcPermanent, {
      cell: (props) => <ListDtc dtcs={props.row.original.activeDtcs ?? []} dtcType={DtcEventType.Permanent} />,
      id: ListColumnId.DtcPermanent,
      header: () => dtcEventTypeDescriptionMap[DtcEventType.Permanent],
      meta: {
        filterComponent: () => someNoneFilter(ListColumnId.DtcPermanent),
      },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.DistanceDriven, {
      cell: ({
        row: {
          original: { distanceDriven },
        },
      }) => (
        <div className="flex-center">
          {(distanceDriven
            ? distanceDriven * conversionRatioMap[TripStatisticType.TotalDistanceDriven]
            : undefined
          )?.toFixed(0) ?? 0}
        </div>
      ),
      id: ListColumnId.DistanceDriven,
      header: () => tripStatisticTypeDescriptionMap[TripStatisticType.TotalDistanceDriven],
      size: 200,
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.DrivingTime, {
      cell: ({
        row: {
          original: { drivingTime },
        },
      }) => <div className="flex-center">{drivingTime}</div>,
      id: ListColumnId.DrivingTime,
      header: () => tripStatisticTypeDescriptionMap[TripStatisticType.TotalTimeDriven],
      size: 200,
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.FuelConsumption, {
      cell: ({
        row: {
          original: { fuelConsumption },
        },
      }) => (
        <div className="flex-center">
          {(fuelConsumption
            ? fuelConsumption * conversionRatioMap[TripStatisticType.FuelConsumption]
            : undefined
          )?.toFixed(0) ?? 0}
        </div>
      ),
      id: ListColumnId.FuelConsumption,
      header: () => tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
      size: 180,
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.FuelEfficiency, {
      cell: ({
        row: {
          original: { fuelEfficiency },
        },
      }) => (
        <div className="flex-center">
          {(fuelEfficiency
            ? fuelEfficiency * conversionRatioMap[TripStatisticType.FuelEfficiency]
            : undefined
          )?.toFixed(0) ?? unavailable_text}
        </div>
      ),
      id: ListColumnId.FuelEfficiency,
      header: () => tripStatisticTypeDescriptionMap[TripStatisticType.FuelEfficiency],
      size: 180,
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.LongIdlingEventCount, {
      cell: (props) => <div>{props.getValue()}</div>,
      id: ListColumnId.LongIdlingEventCount,
      header: () => longIdling_text,
      meta: {
        filterComponent: () => someNoneFilter(ListColumnId.LongIdlingEventCount),
      },
    }),
    columnHelper.accessor<ListColumnId, string>(ListColumnId.IdleTime, {
      cell: (props) => <div>{props.getValue()}</div>,
      id: ListColumnId.IdleTime,
      header: () =>
        idleTimeAsPercentage
          ? tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimePct]
          : tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimeHrs],
    }),
  ];

  return columns;
};
