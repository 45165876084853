import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { dashboardLightsIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import Widget, { WidgetPageType } from 'components/Dashboard/Widget';
import { DashboardLightMessageType } from 'generated/graphql';
import { entries } from 'utils';

import { useVehicleDetails } from '../hooks';
import { DashboardLightIcon } from './DashboardLightIcon';
export const title = 'Dashboard Lights';

const DashboardLights = () => {
  const i18nContext = useContext(I18nContext);
  const { loading, error, data: [vehicleDetails] = [] } = useVehicleDetails();

  if (loading || !vehicleDetails || !i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      errors: { error_text },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  const { activeDashboardLights } = vehicleDetails;

  const allActiveIcons = activeDashboardLights.map((dl) => (
    <DashboardLightIcon
      key={dl.description}
      warning={true}
      description={dl.description}
      iconName={dashboardLightsIconMap[dl.type]}
    />
  ));
  const allIcons = entries(DashboardLightMessageType)
    .filter(([_, dlType]) => !activeDashboardLights.find((dl) => dl.type === dlType))
    .map(([value, dlType]) => (
      <DashboardLightIcon key={value} warning={false} description={value} iconName={dashboardLightsIconMap[dlType]} />
    ));

  return (
    <Widget
      title={tSafe('components.Vehicle.Detail.DashboardLights.title', { defaultValue: 'Dashboard Lights' })}
      warning={!!allActiveIcons.length}
      collapsible
      page={WidgetPageType.Vehicle}
    >
      <div className="relative mt-1 h-full p-1 w-full bg-white overflow-hidden" data-testid="dashboard-lights">
        <div className="grid grid-cols-6 -mt-2 gap-4 items-center p-1 overflow-y-auto">
          {[...allActiveIcons, ...allIcons]}
        </div>

        {!allActiveIcons.length && (
          <div>
            <div className="absolute top-[54px] left-0 right-0 mx-auto flex flex-center justify-center">
              <div className="mr-1 w-3">
                <Icon name="connected" className="text-success text-md" aria-label="Connected" />
              </div>

              <div>
                {tSafe('components.Vehicle.Detail.DashboardLights.no-active-dashboard-lights', {
                  defaultValue: 'No Active Dashboard Lights',
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </Widget>
  );
};

export default DashboardLights;
